<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <h1>Edit Sliders</h1>
        <b-button
          size="sm"
          type="button"
          variant="primary"
          v-b-modal.modal-create
        >
          Add New Slider
        </b-button>
      </div>
      <b-row align-content="center" v-if="settingDetails">
        <b-col cols="12">
          <div
            class="w-100 d-flex align-items-center justify-content-center"
            v-if="!settingDetails.sliders || settingDetails.sliders.length == 0"
          >
            <p class="text-center">No Sliders Available</p>
          </div>
          <div v-else>
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="my-1"
                v-for="(slider, index) in settingDetails.sliders"
                :key="index"
              >
                <b-img
                  class="cursor-pointer image-thumbnail"
                  @click="renderImageModal(slider)"
                  :src="handleImageUrl(slider)"
                  alt=""
                  style="object-fit: contain; height: 100px; max-height: 100px"
                  height="auto"
                  width="200"
                />
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" v-b-modal.modal-create>
          Add New Slider
        </b-button>
      </div>
    </b-card>
    <b-modal
      id="modal-create"
      centered
      size="lg"
      ok-variant="success"
      title="Create New Slider"
      ok-title="Create"
      cancelTitle="Dismiss"
      @ok="pushNewSlider"
    >
      <b-form-file
        ref="refInputEl"
        accept=".jpg, .png, .gif"
        placeholder="Choose Photo"
        @input="makeBase64Pic"
      />
      <div class="w-100 d-flex align-items-center justify-content-center my-2">
        <b-img
          v-if="imagePreview"
          :src="imagePreview"
          height="auto"
          width="400"
          class="rounded image-preview"
        />
      </div>
    </b-modal>
    <library-image-modal
      :tempMedia="tempImage"
      @refetch="getSetting"
    ></library-image-modal>
  </b-overlay>
</template>
<script>
export default {
  title: "تنظیمات اسلایدر",
  mounted() {
    this.getSetting();
  },
  data() {
    return {
      isLoading: false,
      settingDetails: null,
      newSliderDetails: {
        base64: null,
        objectId: 1,
        type: 12, // 12 is for slider
        priority: null,
      },
      imagePreview: null,
      tempImage: null,
    };
  },
  methods: {
    async getSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASingleSetting = new GetASingleSetting(_this);
        getASingleSetting.setRequestParam({ id: 1 });
        await getASingleSetting.fetch((response) => {
          if (response.isSuccess) {
            _this.settingDetails = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async updateSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateASetting = new UpdateASetting(_this);
        updateASetting.setRequestParamDataObj(_this.settingDetails);
        await updateASetting.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Succesful",
                icon: "CheckIcon",
                variant: "success",
                text: "Slider Updated Successfully",
              },
            });
            _this.getSetting();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendCreateFileRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let createFile = new CreateFile(_this);
        createFile.setRequestParamDataObj(_this.newSliderDetails);
        await createFile.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Succesful",
                icon: "CheckIcon",
                variant: "success",
                text: "File Uploaded Successfully",
              },
            });
            _this.getSetting();
            _this.imagePreview = null;
            _this.newSliderDetails = {
              base64: null,
              objectId: 1,
              type: 12, // 12 is for slider
              priority: null,
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    pushNewSlider() {
      this.newSliderDetails.priority =
        this.settingDetails.sliders?.length > 0
          ? this.settingDetails.sliders[0].priority + 1
          : 1;

      this.sendCreateFileRequest();
    },
    renderImageModal(slider) {
      this.tempImage = {
        pictureUrl: slider.base64,
      };
      this.$bvModal.show("details-modal");
    },
    handleImageUrl(slider) {
      if (slider.base64) {
        return `${Helper.baseUrl}/${slider.base64}`;
      }
    },
    async makeBase64Pic() {
      const _this = this;
      let file = _this.$refs.refInputEl.files[0];
      _this.imagePreview = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.newSliderDetails.base64 = result;
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        };
        fr.readAsDataURL(file);
      });
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
    LibraryImageModal,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { GetASingleSetting, UpdateASetting } from "@/libs/Api/Setting";
import { CreateFile } from "@/libs/Api/Media";
import Helper from "@/libs/Helper";
import LibraryImageModal from "@/views/components/utils/LibraryImageModal.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
</script>
<style scoped>
.image-preview {
  max-height: 400px;
  height: 400px;
  object-fit: contain;
}
.image-thumbnail {
  max-height: 130px;
  height: 130px;
  object-fit: contain;
}
</style>
